<template>
  <b-container
    :style="`background-image: url(${sideImg}); background-size: cover;`"
    class="h-100 text-white"
  >
    <b-row
      align-v="center"
      class="h-100 justify-content-center"
    >

      <b-col cols="6">
        <div class="text-center mb-2">
          <h1 class="font-weight-bolder text-white">
            乳牛谷贊助系統
          </h1>
        </div>

        <div class="text-center mb-2">
          <h2 class="font-weight-bolder text-white">
            自助贊助中心, 快速金流繳費, 保障安全隱私
          </h2>
        </div>

        <hr>

        <div class="text-center mb-2">
          <h4 class="font-weight-bolder text-white">
            遊戲伺服器：【乳牛谷】
          </h4>
        </div>

        <b-form-input
          placeholder="請輸入遊戲帳號"
          class="mb-2"
        />

        <cleave
          v-model="showData.money"
          :raw="false"
          :options="{ numeral: true, numeralThousandsGroupStyle: 'thousand' }"
          placeholder="請輸入繳款金額"
          class="mb-2 form-control"
        />

        <b-form-select
          v-model="showData.pt"
          :options="pays"
          class="mb-2"
        />

        <div class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
          >
            確定贊助
          </b-button>
        </div>

      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,

    Cleave,
  },
  directives: {
    Ripple,
  },
  setup() {
    const showData = ref({
      pt: 1,
    })
    /* eslint-disable global-require */
    const sideImg = require('@/assets/images/background/particles_bg.jpg')

    const pays = [
      { text: '超商代碼繳費(最高繳款金額6000)-請選擇此繳費方式', value: 1 },
      { text: 'ATM轉帳繳費(最高繳款金額20000)-請選擇此繳費方式', value: 2 },
      { text: '線上刷卡服務(最高繳款金額20000)-請選擇此繳費方式', value: 3 },
    ]

    return {
      showData,

      sideImg,
      pays,
    }
  },
}
</script>

<style lang="scss">
.form-control {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}

.custom-select {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
